import Carousel from 'react-bootstrap/Carousel';

function ContactCarousel() {
  return (
    <Carousel indicators={false} className="contact-slides-cover">
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/kas1.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/kas2.jpg"
          alt="Second slide"
        />
       
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/kas3.jpg"
          alt="Third slide"
        />
       
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/kas4.jpg"
          alt="Third slide"
        />
       
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/carpentry.jpg"
          alt="Third slide"
        />   
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/p1.jpg"
          alt="Third slide"
        />
       
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/p2.jpg"
          alt="Third slide"
        />
       
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/pipe.jpg"
          alt="Third slide"
        />
       
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/renovation.jpg"
          alt="Third slide"
        />
       
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/supply.jpg"
          alt="Third slide"
        />
       
      </Carousel.Item>





    </Carousel>
  );
}

export default ContactCarousel;
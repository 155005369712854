import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'Completed Projects | KASOLYA LIMITED';

},[]);

  return (
    <section className='section-cover team-cover' >
         <div className='content-container table-container'>
           <h2 className="home-h2 team-h2">Completed Projects</h2>
           <p>KASOLYA LIMITED is proud to be associated with brand names such as Government of Uganda,
American Embassy Uganda, Actionaid Uganda, Welthunger Hilfe, Shumuk Group of
Companies, EU/ALREP, World Vision Uganda.We offer technical solutions to all the
needs and desires of our clients through this we have achieved diversity in our
approach to provide best solutions to our clients needs in a multi cultural
environment
</p>
<p>We have an accumulated wealth of exeperience generated over a period
of time. Our team of workers has been tasted and proven having successfully
completed several projects among which include;
</p>
  <table>
    <tr>
      <th>S/No.</th>
      <th>Client</th>
      <th>Work Description</th>
      <th>Year</th>
    </tr>

     <tr>
      <td>1.</td>
      <td>Soroti Local Government
</td>
      <td>Construction of Adamasiko
piped water supply and
sanitation scheme phase II</td>
      <td>FY 2021/2022
</td>
    </tr>

      <tr>
      <td>2.</td>
      <td>Soroti Local Government
</td>
      <td>Construction of Adamasiko
piped water supply and
sanitation scheme phase I</td>
      <td>FY 2020/2021</td>
    </tr>

    <tr>
      <td>3.</td>
      <td>AAR Japan </td>
      <td>Supply of 1kg Whitestar bar
soap
</td>
      <td>2021
</td>
    </tr>

    <tr>
      <td>4.</td>
      <td>Ruby Medical
Center Lugogo
bypass Kampala</td>
      <td>General Renovations ie
Partitioning of office block,
Tiling, Plastering, Painting,
Installation of a new Plumbing
System.</td>
      <td>5<sup>th</sup>/08/2019
</td>
    </tr>

      <tr>
      <td>5.</td>
      <td>Ruby Medical
Center Lugogo
bypass Kampala</td>
      <td><ul>
        <li>Laying of Network, Installation
of CCTV Cameras and
Telephone System.</li>
<li>Installation of Fire Supression
Sytem</li>
        </ul></td>
      <td>19<sup>th</sup>/12/2019
</td>
    </tr>

    <tr>
      <td>6.</td>
      <td>Actionaid Uganda</td>
      <td><ul>
        <li>Supply of local Zebu cows</li>
        <li>Supply of local Kigezi Goats</li>
        </ul></td>
      <td>27<sup>th</sup>/09/2019
</td>
    </tr>

     <tr>
      <td>7.</td>
      <td>Welthunger Hilfe </td>
      <td>Supply and Installation of
stain-less rainwater harvest
tanks and assorted materials in
5 schools of Katakwi and
Amuria Districts.</td>
      <td>25<sup>th</sup>/04/2019
</td>
    </tr>

     <tr>
      <td>8.</td>
      <td>Masaka
Municipality
</td>
      <td>Supply and installation of street
lights along Alex Sebowa and
Katwe Road
</td>
      <td>19<sup>th</sup>/07/2018
</td>
    </tr>

    <tr>
      <td>9.</td>
      <td>American Embassy
Kampala
</td>
      <td>Supply of LED Lights</td>
      <td>28<sup>th</sup>/09/2017</td>
    </tr>

    <tr>
      <td>10.</td>
      <td>Soroti District
Local Government
</td>
      <td>Construction of a 2 in 1
teachers staff house at Aminit
P/S Kamuda Sub County –
NUSAF 2
</td>
      <td>10<sup>th</sup>/09/2015
</td>
    </tr>

    <tr>
      <td>11.</td>
      <td>Soroti District
Local Government
</td>
      <td>Construction of a 2 in 1 Health
staff house at Dakabela HC III
Arapai Sub County – NUSAF 2
</td>
      <td>10<sup>th</sup>/09/2014
</td>
    </tr>

     <tr>
      <td>12.</td>
      <td>World Vision
Uganda

</td>
      <td>Construction of one pit latrine
of 2 stance with attached bath
shelter
</td>
      <td>10<sup>th</sup>/06/2014

</td>
    </tr>
  
  
  </table>


         </div>
    </section>
  )
}

export default Team
import React from 'react'

let ValuesData = [

{title: "Integrity",      
content: "Integrity always comes first. Honesty, truthfulness, and sincerity are all values we hold at the forefront. We inspire trust in each other and our clients by saying what we mean, matching our behaviors to our words, and taking responsibility for our actions. We are always fair and ethical even in the most difficult situations."
},

{title: "Relationships",      
content: "We build positive and long-term relationships with our clients, partners, vendors, and colleagues that are built on trust, respect, and collaboration."
},
{title: "Delivery",      
content: "We are passionate about excellence and doing our work right the first time. We listen, learn, and seek out the best ideas. We attack complacency and continually improve while delivering value in the eyes of every client and community we serve."
},
{title: "Passion",      
content: "We are passionate about what we do. We mutually respect each other’s work and opinions. We believe in each other and what we can accomplish together. We aspire to be the employer of choice in our industry."
},
{title: "Quality",      
content: "We always strive to excel and set higher benchmarks.  We take pride in providing the best value products and services that we stand by, ensuring client satisfaction and corporate profitability.  Quality is what sets us apart and enables our sustainable growth."
}
];

export default ValuesData;
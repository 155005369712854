import React from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaRegPaperPlane,FaHandshake,FaThumbsUp,FaCheck,FaQuoteLeft} from 'react-icons/fa';
import {IoIosPeople} from 'react-icons/io';
import {RiTeamFill} from 'react-icons/ri';


const Home = () => {
  return (
    <>
    <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">Background</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div>

                 <p className='home-p'><strong>Kasolya Limited</strong> has been providing its clients with reliable solutions to their most
complex construction and supply challenges. Today we are recognized for our
strength in traditional construction methods and for our creative, fresh approach to
cutting edge technologies and delivering systems. Our expertise spans the
construction and supply industry spectrum, from small renovations and supplies to
multi-million projects.
</p>
<p className='home-p'>We offer a design and build package to suit the specific needs of our clients. All works
and supplies are undertaken using the same professional approach and attention to
detail.</p>
<p className='home-p'>Since being founded, we have concentrated our efforts on constructing quality
buildings on a varied range of projects. These have included refurbishment works on
numerous commercial, office, industrial, residential and healthcare premises.
</p>
 
        </div>

    </section>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient( rgba(8, 8, 37, 0.85), rgba(0, 15, 80, 0.675)), url("./images/kas2.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            {/* <h2 className='quote-h3'>Our Pledge</h2>
            <div className='pledge-line'></div> */}
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>Our success is driven by more than building some of the most advanced facilities for
our corporate, institutional, government and individual clientele. More and more of our clients are turning to us for our distinctive ability to implement innovative project management techniques and to serve as a reliable provider of knowledge-driven solutions for their complex construction and Supply projects.
</p>
        </div>
    </section>

    <section className='home-service-cover'>
    {/* <h2 className='home-h2 home-service-h2'>Overview of  
    Optical Fiber our expertise</h2> */}
     <h2 className='home-h2 home-service-h2'>Why choose us?</h2>
    <div className='line1'> <div className='line2'></div></div>
   
    <div className='home-services-container '>
      {/* <div className='about-img-container'>
      <img src='./images/AWUKO1.jpg' alt='BENARD AWUKO' className='about-img'></img>
   </div> */}
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Expertise</h3>
          <p>KASOLYA LIMITED has a depth of project experience and technical expertise. We have earned a solid reputation for professional and reliable project management and performance. </p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaThumbsUp></FaThumbsUp></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>Whatever we do, we give it our best.  Our goal is to provide our clients with first-class services.</p>

        </div>
        {/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaTools></FaTools></span>
          </div>
          <h3 className='home-service-title'>Construction & Installation</h3>
          <p>We provide upto six (6) employment teams, tools and transport to handle survey, Right-of-Way, material supply & installation, optical fiber termination & testing, commissioning, acceptance and handover.</p>
        </div> */}
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><IoIosPeople></IoIosPeople></span>
          </div>
          <h3 className='home-service-title'>People</h3>
          <p>
            We care about people, our clients and our staff.  We believe the sincerity of this statement is proven by our work quality, strong customer service skills and dedication.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake>  </span>
          </div>
          <h3 className='home-service-title'>Accountability</h3>
          <p>We are accountable to our clients concerning project deadlines and we uphold clear communication.</p>
        </div>


        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><RiTeamFill></RiTeamFill></span>
          </div>
          <h3 className='home-service-title'>Teamwork</h3>
          <p>We strive to collaborate with our clients and workers to achieve positive outcomes.  It is our belief that teamwork positions us to move towards a common goal of customer satisfaction and project success. </p>
        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaRegPaperPlane></FaRegPaperPlane></span>
          </div>
          <h3 className='home-service-title'>Dedication</h3>
          <p>Our dedication to Respect, Integrity, Diversity, and Reliability has been the path to success for our company, our employees, and our clients. Our strength lies in our ability to find solutions. </p>
        </div>

        {/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Our Approach</h3>
          <p>Our dedication to Respect, Integrity, Diversity, and Reliability has been the path to success for our company, our employees, and our clients. Our strength lies in our ability to find solutions. </p>
        </div> */}

        
    
    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section>
  <section className='section-cover'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              {/* <h2 className='home-h2 home-service-h2 goals-h2'>Our Goals</h2> */}
              <div className='goals-list goals-list1'>
                     {/* <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div> */}

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span> */}
                          <p className='goal-description'>Our team of construction professionals offers a single source solution for all of your
construction related needs. Whether its restoration, site preparation, a new facility
or facility renovation, we have the experience and personnel to provide the highest
quality construction on schedule and within budget. </p>
                     </div>

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span> */}
                          <p className='goal-description'>We support our clients from the time of inception, to the commissioning of the fully
operational facility. We offer our clients technical advice on step by step basis about
the project to be under taken in order for them to make informed decisions. This has greatly simplified our working relations with all our clients and has often
than not left a smile on their faces. This advice ranges from planning, budgeting,
financing and may also include environmental implications where necessary. </p>
                     </div>

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span> */}
                          <p className='goal-description'>
                            Our company has adopted to the ever changing technologies in the construction and
supply management systems through consistently training our staff, research and
refresher courses to equip them with the necessary skills to tackle any arising
challenges in the business. We thrive through offering affordable, reliable, effective
and realistic services to our clientele. 
                             </p>
                     </div>
<Link to="about-us" className='home-btn'>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article>




       </div>

        <div className='content-container goals-container goals-container1'>
           <article className='goals-text goals-text1'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Safety and Health</h2>
             <div className='line1'> <div className='line2'></div></div>
              <div className='goals-list goals-list1'>
                     {/* <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div> */}

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span> */}
                          <p className='goal-description'>Our key strategic objective is the achievement of "zero harm". Site Foremen and
management ensure that safety is not compromised in any circumstances by taking
responsibility for employee safety, leading by example, encouraging positive safety
behavior and discouraging negative behavior. Site Foremen apply a policy of zero
tolerance to non-compliance with safety standards. The company undergoes regular
Safety Audits.</p>
                     </div>

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span> */}
                          <p className='goal-description'>Cases of occupational and non-occupational disease are referred to external facilities.
No pre-employment testing for HIV is undertaken and all HIV testing and counseling
is undertaken voluntarily. Employees who are HIV positive are encouraged to
participate in the wellness programs available through various local clinics.</p>
                     </div>

                    
{/* <Link to="about-us" className='home-btn'>
         More About Us
         </Link> */}
                </div>
                   

           </article>

           {/* <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article> */}




       </div>

        <div className='content-container goals-container goals-container1'>
           <article className='goals-text goals-text1'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Key Registrations</h2>
             <div className='line1'> <div className='line2'></div></div>
              <div className='goals-list goals-list1'>
                     {/* <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div> */}

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span> */}
                          <p className='goal-description'>Kasolya Limited is proud to be registered to do business with International
Organisations and Governments among which include;<br></br>
US Government – DUNS No: 561224842<br></br>
United Nations – UNGM No: 548106<br></br>
British Government - DFID</p><br></br>
                     </div>

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span> */}
                          <p className='goal-description'>We pride ourselves on managing our clients and individual`s requirements and
expectations, safely, commercially and on time with a proactive “can do” attitude.</p>
                     </div>

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span> */}
                          <p className='goal-description'>We continually review our quality control procedures and on site working practices
to ensure our clients receive the highest quality workmanship.</p>
                     </div>

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span> */}
                          <p className='goal-description'>Make a partnership with us today and you shall never have any regrets. </p>
                     </div>

                    
{/* <Link to="about-us" className='home-btn'>
         More About Us
         </Link> */}
                </div>
                   

           </article>

           {/* <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article> */}




       </div>

  </section>
    
  
    </>
  )
}

export default Home
import Carousel from 'react-bootstrap/Carousel';

function VisionCarousel() {
  return (
    <Carousel indicators={false}>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/31.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/32.jpg"
          alt="Second slide"
        />
       
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/kas1.jpg"
          alt="Third slide"
        />
       
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/kas2.jpg"
          alt="Third slide"
        />
       
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/kas3.jpg"
          alt="Third slide"
        />   
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/kas4.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/tile.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/pipe.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/v1.jpeg"
          alt="Third slide"
        /> 
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/gs.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/d1.jpeg"
          alt="Third slide"
        /> 
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/d2.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>

    </Carousel>
  );
}

export default VisionCarousel;
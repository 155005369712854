import React from 'react';
import {FaMapMarkerAlt, FaFacebookSquare,FaTwitterSquare,FaLinkedin,FaPhoneAlt,FaEnvelope} from 'react-icons/fa'
import {Link} from 'react-router-dom';
const TopSection = () => {
  return (
    <section className='section-cover top-cover'>
    <section className='top-section1'>

      <div className='my-container'>
            <div className='top-location-contact-email'>
                 <span className="top-icon"> <FaMapMarkerAlt></FaMapMarkerAlt></span><span>Mandela National Stadium, P. O. Box 14669, Kampala - Uganda</span>
            </div>

            <div className='top-location-contact-email'>
                 <span className="top-icon"> <FaPhoneAlt></FaPhoneAlt></span><span>+256775 301375 | +256750 991142</span>
            </div>

            <div className='top-location-contact-email'>
            <Link to='#contact'>     <span className="top-icon"> <FaEnvelope></FaEnvelope></span><span>info@kasolyaltd.com | kasolyaltd@gmail.com</span></Link>
            </div>
            
            {/* <div className='top-icons'>
             <span>  <FaFacebookSquare></FaFacebookSquare>  </span> 
             <span>    <FaTwitterSquare></FaTwitterSquare></span> 
               <span>   <FaLinkedin></FaLinkedin></span>
                  
            </div> */}
        </div>
        {/* <div className='container'>
            <div className='logo-container'>
              <img src='./images/logo.jpg' alt='logo' className='logo'></img>
            </div>
            
            <div className='top-content'>
                  
            </div>
        </div> */}
    
    </section>
    </section>
  )
}

export default TopSection